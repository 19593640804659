<template>
    <div class="enterprise">
		<div class="breadcrumbTop">
			<a-breadcrumb>
			   <a-breadcrumb-item @click='$router.back()'>自定义设置</a-breadcrumb-item>
			   <a-breadcrumb-item><a href="">工时制度设置</a></a-breadcrumb-item>
			 </a-breadcrumb>
			 <div class="tips_top_box" >
			 	<p> 您可以根据公司情况设置需要的工时制度</p>
			 </div>
			 <div class="FieldSettingsBox">
			     <div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
			         <span>
			              &nbsp; &nbsp;
			         </span> <span>学历</span> <span></span> <span style="color: #666;">启用/禁用</span>
			     </div>
			    
			   <!--  <draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
			         @start="onStart" @end="onEnd">
			         <transition-group> -->
			             <div  :draggable="true"
                                    @dragstart="dragstart($event, -1, index)"
                                    @dragover="dragover"
                                    @dragenter="dragenter($event, -1, index)"
                                    @dragend="dragend($event, -1, index)"
                                    @drop="drop" v-show="element.status==1" class="FieldSettingsBoxTitle" v-for="(element,index) in all_list" >
			                 <span>
			                   <a-icon type="unordered-list" />
			                 </span>
			                 <span>
			                     {{element.name}}
			                 </span>
							   <span  >  </span>
			                 <span ><a-switch   @click='change_status(element.id)' v-model:checked="element.status_check" /></span>
			             </div>
			        <!-- </transition-group>
			     </draggable> -->
			 	<div v-show="element.status==-1" class="FieldSettingsBoxTitle" v-for="element in all_list" >
			 	    <span>
			 	       &nbsp; &nbsp;
			 	    </span>
					 <span  >{{element.name}}</span>
			 	   <span  ></span>
			 	   <span ><a-switch   @click='change_status(element.id)' v-model:checked="element.status_check" /></span>
			 	</div>
			 </div>
			        
			
		</div>
    </div>
</template>

<script>
   import {
      editCustomSettingCate,getCustomSettingInfo,getCustomSettingDetail,changeCustomSettingStatic,changeCustomSettingStatus,sortCustomSetting,addCustomSettingCate
    } from '@/api/enterprise.js'
    import {
        message,
        TreeDataItem,
        TreeDragEvent,
        DropEvent,
        Modal
    } from 'ant-design-vue';

    export default {
        components: {

        },
        data() {
            return {stop_index: 0,
				stop_approval_index: 0,
				all_list:[],
				visit2:true,
                labelColEdit: {
                    span: 4
                },
                wrapperColEdit: {
                    span: 20
                },
                wrapperCol: {
                    span: 12
                },
                select_list: [],
				type:'',
            }

        },
        created() {
           this.type = this.$route.query.type
            this.getIndustry()
        },
        methods: {
			dragstart(e, ind) {
			    // e.preventDefault()
			},
			drop(e) {
			    e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
			    e.preventDefault()
			    if (ind != -1) {
			        this.stop_index = ind;
			    }
			    this.stop_approval_index = index
			},
			dragover(e) {
			    e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
			   let list = this.all_list;
			   let move_data = list[index];
			   list.splice(index, 1)
			   list.splice(this.stop_approval_index, 0, move_data)
			   this.all_list = list;
			   this.onEnd()
			
			},
			change_forbid(id){
				changeCustomSettingStatic({data:{
					id:id
				},info:true}).then(res=>{
					   this.getIndustry()
				})
			},
			change_status(id){
				changeCustomSettingStatus({data:{
					id:id
				},info:true}).then(res=>{
					   this.getIndustry()
				})
			},
			onStart(){
				 this.drag = true;
			},
			onEnd(){
				 this.drag = false;
				 let sort=[]
				 this.all_list.forEach(item=>{
					 sort.push(item.id)
				 })
				 sortCustomSetting({data:{
				 	sort:sort.join(',')
				 },info:true}).then(res=>{
				 	   this.getIndustry()
				 })
			},
         
            getIndustry() {
                getCustomSettingDetail({data:{
					type:this.type
				},info:true}).then(res=>{
					res.data.list.content.forEach(item=>{
						item.status_check =	item.status==1?true:false
					})
                    this.all_list = res.data.list.content
                })
            },
            handle_submit() {

            }
        },

    }
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
    @import 'info.less';
	.breadcrumbTop{
		flex: 1;
	}
    .table_list_body {
        margin: 0;
        padding: 0;
    }
    .check{
        color: @cq_color;
        cursor: pointer;
    }
    .del{
        color: @cq_error;
    }
	.FieldSettingsBox {
	    border: 1px solid @cq_line_e;
	    margin-top: 12px;
	}
	.FieldSettingsBoxTitle {
	    display: flex;
	    width: 100%;
	    padding: 8px 12px;
	    border-bottom: 1px solid @cq_line_e;
	    span:nth-child(2) {
	        flex: 4;
	        margin-left: 8px;
	    }
	    span:nth-child(3) {
	        flex: 1;
	    }
	    span:nth-child(4) {
	        color: #fff;
	
	    }
	}
	
	.FieldSettingsBoxHeader {
	    background: #f5f8fa;
	}
	
	.FieldSettingsBoxTitle:hover {
	    background: #f5f8fa;
	
	    span:nth-child(4) {
	        color: #666;
	    }
	
	}
</style>
